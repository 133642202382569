export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let id = param.get("id");
  let feed_id = param.get("feed_id");

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!feed_id) {
    feed_id = localStorage.getItem("feed_id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    if (id === "1011") {
      window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
    } else if (id === "1007") {
      window.location.href = `https://arumo.top/tf6S7FF5?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1009") {
      window.location.href = `https://arumo.top/sL2Sd9S5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1008") {
      window.location.href = `https://arumo.top/wPJBrJ69?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1205") {
      window.location.href = `https://arumo.top/hKdvYDmg?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1293") {
      window.location.href = `https://arumo.top/NcvRv43S?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1294") {
      window.location.href = `https://arumo.top/n7pkbd6L?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1295") {
      window.location.href = `https://arumo.top/TD6vhvj5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
    } else if (id === "1296") {
      window.location.href = `https://arumo.top/3V49F4xD?creative_id=${creative_id}&source=${source}`;
    } else if (id === "1297") {
      window.location.href = `https://arumo.top/4tYkbq3j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1456") {
      window.location.href = `https://arumo.top/npzdbgnZ?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1457") {
      window.location.href = `https://arumo.top/hZwMCQWN?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&feed_id=${feed_id}`;
    } else if (id === "1462") {
      window.location.href = `https://arumo.top/k9c6KQyg?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1463") {
      window.location.href = `https://arumo.top/FRPsg5yJ?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1464") {
      window.location.href = `https://arumo.top/wzj92L7Y?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1465") {
      window.location.href = `https://arumo.top/gJmWsx8M?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else {
      window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
    }
  }, 200);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  return <div></div>;
};
